<script lang="ts" setup>
import ToolgridContentComponent from './ConfigNavigationContent/ConfigNavigationContentToolgrid.vue'
import MountpanelContentComponent from './ConfigNavigationContent/ConfigNavigationContentMountpanel.vue'
import ElectricityprepareContentComponent from './ConfigNavigationContent/ConfigNavigationContentElectricityprepare.vue'
import NoneOnlyDoorsContentComponent from './ConfigNavigationContent/ConfigNavigationContentNoneOnlyDoors.vue'
import { Fill } from '~/types/Fill'
import { CaseType } from '~/types/CaseType'
import { CaseSerie } from '~/types/CaseSerie'

const { nextTab } = useConfigNavigation()
const { changeFill } = useCaseConfig()

const { caseConfig } = storeToRefs(useCaseConfig())

const options = computed(() => {
  const allOptions: { id: number, label: string, price?: number, contentComponent?: any }[] = [
    { id: Fill.toolGrid, label: 'Přístrojový rošt', price: caseConfig.value.toolgridPrice(), contentComponent: ToolgridContentComponent },
    { id: Fill.mountPanel, label: 'Montážní panel', price: caseConfig.value.mountPanelPrice(), contentComponent: MountpanelContentComponent },
    { id: Fill.electricityPrepare, label: 'Elektroměrová příprava', price: caseConfig.value.electricityPreparePrice(), contentComponent: ElectricityprepareContentComponent },
    { id: Fill.emptyCase, label: 'Prázdná skříň' },
  ]

  if (caseConfig.value.caseType === CaseType.szl) {
    allOptions.splice(1, 1)
    allOptions.splice(2, 1)
    allOptions.push({ id: Fill.noneOnlyDoors, label: 'Samostatný rám s dveřmi', price: undefined, contentComponent: NoneOnlyDoorsContentComponent })
  }

  if (caseConfig.value.caseSerie === CaseSerie.p)
    allOptions.splice(2, 1)
  return allOptions
})

function handleFillChange (idFill: number) {
  if (idFill === caseConfig.value.fill?.fillValue)
    return
  changeFill(idFill)
}
</script>

<template>
  <div flex flex-col gap-16px>
    <SelectablePill
      v-for="option in options" :key="option.label" :label="option.label"
      :is-active="option.id === caseConfig.fill?.fillValue" :suffix="option?.price ? `+ ${formatPrice(option.price)} Kč` : undefined"
      @click.prevent="handleFillChange(option.id)"
    >
      <template v-if="option.contentComponent" #content>
        <component :is="option.contentComponent" />
      </template>
    </SelectablePill>
    <button :disabled="caseConfig.fill!.fillValue === 6 || (caseConfig.fill!.fillValue === 5 && !caseConfig.isElectricityAvailable)" :class="{ 'app-btn-loading': caseConfig.fill!.fillValue === 6 || (caseConfig.fill!.fillValue === 5 && !caseConfig.isElectricityAvailable) }" type="button" app-btn w-full mt-8px @click="nextTab()">
      Pokračovat
    </button>
  </div>
</template>
