<script lang="ts" setup>
import { Case } from '~/types'
import { Fill } from '~/types/Fill'
import { CustomSizeType, SizeType } from '~/types/SizeType'
import { Dimensions } from '~/types/Dimensions'
import { CaseSerie } from '~/types/CaseSerie'

const { nextTab } = useConfigNavigation()
const { caseConfig } = storeToRefs(useCaseConfig())

const { findCatalogCase } = useRemoteConfig()

const { pickedEi, caseInfo, pickedHeight, pickedDepth, pickedWidth, eiOptions, caseOptions, heightOptions, widthOptions, depthOptions } = storeToRefs(useStepOne())
const { colorOptions, prefillDataFromCaseInfo } = useStepOne()

function reCheckDimensions (ci: Case) {
  caseConfig.value.pickedHeight = heightOptions.value.find(o => o.value === (caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? ci.inDimensions.h : ci.outDimensions.h))?.value
  caseConfig.value.pickedWidth = widthOptions.value.find(o => o.value === (caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? ci.inDimensions.w : ci.outDimensions.w))?.value
  caseConfig.value.pickedDepth = depthOptions.value.find(o => o.value === (caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? ci.inDimensions.d : ci.outDimensions.d))?.value
}

watch(caseInfo, () => {
  if (!caseInfo.value) {
    caseConfig.value.pickedWidth = undefined
    caseConfig.value.pickedHeight = undefined
    caseConfig.value.pickedDepth = undefined
    caseConfig.value.color = colorOptions[0].value
    caseConfig.value.fill = new Fill()
    if (caseConfig.value.sizeType === SizeType.catalog)
      caseConfig.value.pickedEi = undefined
    return
  }
  prefillDataFromCaseInfo(caseInfo.value)
})

const customSizeType = computed(() => caseConfig.value.customSizeType)
watch(customSizeType, () => {
  if (!caseInfo.value)
    return
  reCheckDimensions(caseInfo.value)
})

const sizeType = computed(() => caseConfig.value.sizeType)

watch(sizeType, (curr, prev) => {
  if (prev === SizeType.custom && curr === SizeType.catalog) {
    caseConfig.value.caseInfo = undefined
    caseConfig.value.pickedEi = undefined
    return
  }
  if (sizeType.value === SizeType.custom) {
    caseConfig.value.customHeight = pickedHeight.value || undefined
    caseConfig.value.customWidth = pickedWidth.value || undefined
    caseConfig.value.customDepth = pickedDepth.value || undefined
  }
})

watch([pickedEi, pickedHeight, pickedWidth, pickedDepth], async () => {
  if (!(pickedEi.value && pickedHeight.value && pickedWidth.value && (pickedDepth.value || caseConfig.value.isSzl) && !caseInfo.value)) {
    if (caseInfo.value && !caseConfig.value.isCustomDimensions) {
      caseConfig.value.caseInfo = caseOptions.value.find(c => c.value.label.split('-EI')[0] === caseInfo.value!.label.split('-EI')[0] && c.value.ei === pickedEi.value)!.value
    }
    return
  }

  caseConfig.value.caseInfo = caseOptions.value.find(c => {
    const dimensionsByType = caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? c.value.inDimensions : c.value.outDimensions
    return c.value.ei === pickedEi.value! && dimensionsByType.h === pickedHeight.value! && dimensionsByType.w === pickedWidth.value! && (dimensionsByType.d === pickedDepth.value! || caseConfig.value.isSzl)
  })?.value
})

const customHeight = computed(() => caseConfig.value.customHeight)
const customWidth = computed(() => caseConfig.value.customWidth)
const customDepth = computed(() => caseConfig.value.customDepth)

function checkDimensionsRestrictions (limits: Dimensions, skipDepth?: boolean) {
  console.log(limits.h)
  if ((caseConfig.value?.customHeight ?? 0) > limits.h)
    caseConfig.value.customHeight = limits.h
  if ((caseConfig.value?.customWidth ?? 0) > limits.w)
    caseConfig.value.customWidth = limits.w
  if (skipDepth)
    return
  if ((caseConfig.value?.customDepth ?? 0) > limits.d)
    caseConfig.value.customDepth = limits.d
}

watch([customHeight, customWidth, customDepth], () => {
  const maxSrDimensions = new Dimensions(700, 2050, 1300)
  const maxSpDimensions = new Dimensions(420, 2090, 1060)
  const maxSzlDimensions = new Dimensions(0, 2090, 1260)

  checkDimensionsRestrictions(caseConfig.value.isSp ? maxSpDimensions : caseConfig.value.isSr ? maxSrDimensions : maxSzlDimensions, caseConfig.value.isSzl)

  if (!customHeight.value || !customWidth.value || !caseConfig.value.pickedEi || (!customDepth.value && !caseConfig.value.isSzl)) {
    caseConfig.value.caseInfo = undefined
    return
  }
  const customDimensions = new Dimensions(customDepth.value ?? 0, customHeight.value, customWidth.value)

  const widthDiff = 150
  const heightDiff = 150
  const depthDiff = 50

  const customCase = new Case(caseConfig.value.caseSerie!, caseConfig.value.caseType!, caseConfig.value.pickedEi!, caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? customDimensions : new Dimensions((customDepth?.value ?? depthDiff) - depthDiff, customHeight.value - heightDiff, customWidth.value - widthDiff), caseConfig.value.customSizeType === CustomSizeType.outerDimensions ? customDimensions : new Dimensions((customDepth.value ?? -150) + depthDiff, customHeight.value + heightDiff, customWidth.value + widthDiff), 0, '', 0, 0, 0)
  if (caseConfig.value.isSzl) {
    customCase.inDimensions.d = 247
    customCase.outDimensions.d = 20
  }
  const foundCase = findCatalogCase(customCase.caseType, customCase.ei, caseConfig.value.customSizeType!, caseConfig.value.customSizeType === CustomSizeType.outerDimensions ? customCase.outDimensions : customCase.inDimensions)

  if (foundCase) {
    caseConfig.value.caseInfo = foundCase
    return
  }

  let type = 'SP'
  if (caseConfig.value.isSr)
    type = 'SR'
  if (caseConfig.value.isSzl)
    type = 'SZ L'
  let heightLabel = (Math.floor(customCase.outDimensions.h / 100)).toString()
  if (heightLabel.length === 1)
    heightLabel = `0${heightLabel}`
  const label
          = `${type} ${heightLabel.substring(0, 2)}${customCase.outDimensions.w.toString().substring(0, 1)}${(customCase.outDimensions.w > 999 ? 0 : '')}${customCase.outDimensions.d.toString().substring(0, 1)}-EI ${caseConfig.value.pickedEi.toString()} DP1-S(a) / S(200)`

  customCase.label = label
  caseConfig.value.caseInfo = customCase
})

const tierLabel = computed(() =>
  caseConfig.value.caseSerie === CaseSerie.p ? { label: 'Px-R', placeholder: 'P-R' } : { label: 'EI', placeholder: 'EI' },
)
</script>

<template>
  <div>
    <div v-if="caseConfig.caseType">
      <Toggle
        v-if="caseConfig.caseSerie === CaseSerie.ei"
        v-model="caseConfig.sizeType" title="Rozměry skříně"
        :options="[{ title: 'KATALOGOVÉ', value: SizeType.catalog }, { title: 'VLASTNÍ', value: SizeType.custom }]" mt-8px
      />
      <Toggle
        v-model="caseConfig.customSizeType" title="Upravit rozměry"
        :options="[{ title: 'VNITŘNÍ', value: CustomSizeType.innerDimensions }, { title: 'VNĚJŠÍ', value: CustomSizeType.outerDimensions }]" mt-16px
      />
      <div flex flex-col gap-24px mt-24px>
        <KLabelSelect :model-value="eiOptions.find((o) => o.value === caseConfig.pickedEi) ?? undefined" :options="eiOptions" :label="tierLabel.label" :placeholder="`Vyberte ${tierLabel.placeholder}`" do-not-lock @update:model-value="(o) => caseConfig.pickedEi = o?.value" />
        <KLabelSelect v-if="caseConfig.sizeType === SizeType.catalog && caseOptions.length" :model-value="caseConfig.caseInfo ? ({ label: caseConfig.caseInfo.label.replaceAll('DP1-S(a) / S(200)', ''), value: caseConfig.caseInfo }) : undefined" :options="caseOptions" label="Označení" placeholder="Vyberte Označení" option-suffix-icon="⭐" :options-with-suffix="caseOptions.filter((option) => option.value.stock)" lock-on-init @update:model-value="(o) => caseConfig.caseInfo = o?.value" />
        <template v-if="caseConfig.sizeType === SizeType.catalog">
          <KLabelSelect
            :model-value="heightOptions.find((o) => o.value === caseConfig.pickedHeight) ?? undefined" :options="heightOptions" label="Výška" placeholder="Vyberte výšku" suffix="mm"
            lock-on-init @update:model-value="(o) => caseConfig.pickedHeight = o?.value"
          />
          <KLabelSelect
            :model-value="widthOptions.find((o) => o.value === caseConfig.pickedWidth) ?? undefined" :options="widthOptions" label="Šířka" placeholder="Vyberte šířku" suffix="mm"
            lock-on-init @update:model-value="(o) => caseConfig.pickedWidth = o?.value"
          />
          <KLabelSelect
            v-if="!caseConfig.isSzl"
            :model-value="depthOptions.find((o) => o.value === caseConfig.pickedDepth) ?? undefined" :options="depthOptions" label="Hloubka" placeholder="Vyberte hloubku" suffix="mm"
            lock-on-init @update:model-value="(o) => caseConfig.pickedDepth = o?.value"
          />
        </template>
        <template v-else>
          <KInput :model-value="caseConfig.customHeight ?? undefined" type="number" name="customHeight" suffix="mm" suffix-size="14" label="Výška" placeholder="Zadejte výšku" inline @update:model-value="(val) => caseConfig.customHeight = val" />
          <KInput :model-value="caseConfig.customWidth ?? undefined" type="number" name="customWidth" suffix="mm" suffix-size="14" label="Šířka" placeholder="Zadejte šířku" inline @update:model-value="(val) => caseConfig.customWidth = val" />
          <KInput v-if="!caseConfig.isSzl" :model-value="caseConfig.customDepth ?? undefined" type="number" name="customDepth" suffix="mm" suffix-size="14" label="Hloubka" placeholder="Zadejte hloubku" inline @update:model-value="(val) => caseConfig.customDepth = val" />
        </template>
        <KLabelSelect :model-value="colorOptions.find((o) => o.value === caseConfig.color) ?? undefined" :options="colorOptions" only-show-select-on-reset :reset-value="colorOptions[0]" label="Barva" placeholder="Vyberte barvu" @update:model-value="(o) => caseConfig.color = o?.value" />
      </div>
      <button :disabled="!caseInfo" :class="{ 'app-btn-loading': !caseInfo }" type="button" app-btn w-full mt-24px @click="nextTab()">
        Pokračovat
      </button>
    </div>
    <div v-else rounded-12px p-12px card-shadow bg-white>
      <h1 text="14px app-text-dark" font-800>
        Vyberte typ skříně
      </h1>
      <p text="12px app-text-black" font-500>
        Kliknutím na obrázek vyberete typ skříně.
      </p>
    </div>
  </div>
</template>
